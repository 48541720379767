import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo";
import BaseLayout from "../components/layout"
import HorizontalRule from "../components/horizontal-rule";
import ChapterSummary from "../components/chapter-summary";
import NewsletterSignup from "../components/newsletter-signup";
import BreadcrumbTrail from "../components/breadcrumb-trail";

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const tagHeader = `Writing · ${tag} (${pageContext.currentPage}/${pageContext.numPages})`
  const pageDesc = `Search through a list of Zack's stories that are in the "${tag}" genre`

  const isFirst = pageContext.currentPage === 1
  const isLast = pageContext.currentPage === pageContext.numPages
  const prevPage = pageContext.currentPage - 1 === 1 ? pageContext.baseUrl : `${pageContext.baseUrl}/${(pageContext.currentPage - 1).toString()}`
  const nextPage = `${pageContext.baseUrl}/${(pageContext.currentPage + 1).toString()}`;

  function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length-size);
  }
  let chapters = data.allMdx.nodes;

  let breadcrumbList = [
    [
      {
        title: 'Home',
        url: '/'
      },
      {
        title: 'Library',
        url: '/writing/library/'
      },
      {
        title: 'Genres',
        url: `/writing/genres/`
      }
    ]
  ]
  if (pageContext.currentPage > 1) {
    breadcrumbList[0].push({
      title: tag,
      url: pageContext.baseUrl
    })
    breadcrumbList[0].push({
      title: `Page ${pageContext.currentPage}`,
    })
  } else {
    breadcrumbList[0].push({
      title: tag,
    })
  }

  return (
    <BaseLayout>
      <Seo title={tagHeader} desc={pageDesc} pathname={pageContext.baseUrl} breadcrumbList={breadcrumbList}></Seo>
      <div className="layout-base-w app-p-base">
        <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
        <div className="mb-28">
          <div className="text-mint">
            <HorizontalRule ruleType="1"></HorizontalRule>
            {isFirst &&
              <h1 style={{'maxWidth': '620px'}} className="text-header-1 w-full">"{tag}" Chapters</h1>
            }
            {!isFirst &&
              <h1 style={{'maxWidth': '620px'}} className="text-header-1 w-full">"{tag}" Chapters ({pageContext.currentPage}/{pageContext.numPages})</h1>
            }
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-40 mt-28 mb-40">
            {chapters.slice(0, 2).map((chapter, index) => (
              <ChapterSummary key={index} chapter={chapter}></ChapterSummary>
            ))}
          </div>

          {isFirst &&
            <div className="my-40">
              <NewsletterSignup></NewsletterSignup>
            </div>
          }

          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-40 mt-28 mb-40">
            {chapters.slice(2, chapters.length).map((chapter, index) => (
              <ChapterSummary key={index} chapter={chapter}></ChapterSummary>
            ))}
          </div>
        </div>

        <div className="text-mint my-40">
          <HorizontalRule ruleType="5"></HorizontalRule>
        </div>

        {(pageContext.numPages > 1) && (
          <div className="flex justify-between my-40 border-t-base border-b-base">
            <div className="flex-2 sm:flex-0 grow shrink sm:flex justify-start">
              {!isFirst &&
                <Link to={prevPage}><button className="btn btn-full sm:btn-medium md:btn-wide btn-white [width:100%] sm:[width:175px] md:[width:225px]">← Last</button></Link>
              }
              {isFirst &&
                <div className="[width:100%] sm:[width:175px] md:[width:225px]"></div>
              }
            </div>
            <div className="flex items-center justify-center bg-mint px-12 text-white">
              <p className="text-pagination-decoration">{pad(pageContext.currentPage, 2)} of {pad(pageContext.numPages, 2)}</p>
            </div>
            <div className="flex-2 sm:flex-0 grow shrink sm:flex justify-end">
              {!isLast &&
                <Link to={nextPage}><button className="btn btn-full sm:btn-medium md:btn-wide btn-white [width:100%] sm:[width:175px] md:[width:225px]">Next →</button></Link>
              }
              {isLast &&
                <div className="[width:100%] sm:[width:175px] md:[width:225px]"></div>
              }
            </div>
          </div>
        )}
      </div>
    </BaseLayout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String, $skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___published_date], order: DESC }
      filter: { frontmatter: { published: { eq: true }, record_type: { eq: "story" }, tags: { in: [$tag] } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      nodes {
        ...ArticleSnippet
      }
    }
  }
`